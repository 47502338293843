import logo from './logo.svg'
import React, {useState, useEffect} from 'react'
import {Switch, Route, Link} from 'react-router-dom'
import './App.css'

import AuthService from './services/auth.service'

import Login from './components/Login'
import Register from './components/Register'
import Home from './components/Home'
import Profile from './components/Profile'
import BoardUser from './components/BoardUser'
import BoardModerator from './components/BoardModerator'
import BoardAdmin from './components/BoardAdmin'

const App = () => {
	const [showModeratorBoard, setShowModeratorBoard] = useState(false)
	const [showAdminBoard, setShowAdminBoard] = useState(false)
	const [currentUser, setCurrentUser] = useState(undefined)

	useEffect(() => {
		const user = AuthService.getCurrentUser()

		if (user) {
			setCurrentUser(user)
			setShowModeratorBoard(user.roles.includes("ROLE_MODERATOR"))
			setShowAdminBoard(user.roles.includes("ROLE_ADMIN"))
		}
	}, [])

	const logOut = () => {
		AuthService.logout()
	}


	return (
		<div className="App">
			<nav className="navbar">
				{/* <Link to={"/"} className="navbar-brand">
					AppMoSoft
        		</Link> */}
					<li className="navItem">
						<Link to={"/home"} className="navLink">
							Home
            			</Link>
					</li>

					{showModeratorBoard && (
						<li className="navItem">
							<Link to={"/mod"} className="navLink">
								Moderator Board
              				</Link>
						</li>
					)}

					{showAdminBoard && (
						<li className="navItem">
							<Link to={"/admin"} className="navLink">
								Admin Board
             				</Link>
						</li>
					)}

					{currentUser && (
						<li className="navItem">
							<Link to={"/user"} className="navLink">
								User
              				</Link>
						</li>
					)}

				{currentUser && (
						<li className="navItem">
							<Link to={"/profile"} className="navLink">
								{currentUser.username}
							</Link>
						</li>
				)}
				{currentUser && (
						<li className="navItem">
							<a href="/login" className="navLink" onClick={logOut}>
								LogOut
              				</a>
						</li>
				)}
				{!currentUser && (
				<li className="navItem">
					<Link to={"/login"} className="navLink">
						Login
              					</Link>
				</li>
				)}
				
				{!currentUser && (
					<li className="navItem">
						<Link to={"/register"} className="navLink">
							Sign Up
              					</Link>
					</li>
				)}
		
			</nav>
			<div className="pageContent">
				<div className="leftHome">
					<img src={logo} className="App-logo" alt="logo" />
					<p className="textNote">Need a Logo</p>
					<h1>AppMoSoft</h1>
				</div>
				<div className="rightHome container mt-3">
					<Switch>
						<Route exact path={["/", "/home"]} component={Home} />
						<Route exact path="/login" component={Login} />
						<Route exact path="/register" component={Register} />
						<Route exact path="/profile" component={Profile} />
						<Route path="/user" component={BoardUser} />
						<Route path="/mod" component={BoardModerator} />
						<Route path="/admin" component={BoardAdmin} />
					</Switch>
				</div>
			</div>
		</div>
	)
}

export default App
